$buttonLoaderSize: 30px;
$imageLoaderSize: 60px;
$fullscreenLoaderSize: 120px;
$loaderColor: #4582EC;

.corporate-progress-bar {
  background-color: $loaderColor !important;
}

.corporate-fullscreen-loader {
  position: fixed;
  top: 0;
  lefT: 0;
  widtH: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
}

.corporate-button-loader:after,
.corporate-img-loader:before,
.corporate-fullscreen-loader:before {
  border-radius: 50%;
  border-top: 2px solid $loaderColor;
  border-right: 2px solid transparent;
  animation: spinner 0.7s linear infinite;
}

.corporate-button-loader:after {

  content: "";
  box-sizing: border-box;
  display: inline-block;
  height: $buttonLoaderSize;
  width: $buttonLoaderSize;
  margin-left: 10px;
}

.corporate-img-loader:before {

  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  height: $imageLoaderSize;
  width: $imageLoaderSize;
  margin-top: calc(-#{$imageLoaderSize} / 2);
  margin-left: calc(-#{$imageLoaderSize} / 2);
}

.corporate-fullscreen-loader:before {
  
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  height: $fullscreenLoaderSize;
  width: $fullscreenLoaderSize;
  margin-top: calc(-#{$fullscreenLoaderSize} / 2);
  margin-left: calc(-#{$fullscreenLoaderSize} / 2);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}